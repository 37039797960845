@import '../../style/variables.less';

.section-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
}

.chat-wrapper {
  width: clamp(300px, 700px, 90%);
  height: 100%;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.disclaimer-text {
  font-size: 12px !important;
  line-height: 130% !important;
  color: rgba(10, 37, 64, 0.6) !important;
  margin-top: 18px;
  margin-bottom: 18px;

  @media screen and (max-width: 590px) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}


#conversation-container {
  width: 70vw;
}

#conversation-table {
  width: 100%;
  padding-bottom: 30px;
  padding-inline: 20px;
}

.login-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}


* {
  box-sizing: border-box;
}

.space-chat-container {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .chat-messages-list-container {
    height: inherit;
    margin-bottom: 12px;
  }
}

#form-button {
  height: 48px;
  background: @text-color;
  border-radius: 48px;
  color: @opposite-primary-color;
  text-transform: uppercase;
  box-shadow: 0 3px 20px 1px #00000008;
}

#form-button:hover {
  background: @message-color;
}

.form-item-container {
  background-color: @opposite-primary-color;
  box-shadow: 0 3px 20px 1px #00000008;
  border-radius: 10px;
  padding: 16px 0 8px 0;
  margin-bottom: 16px;

  .ant-form-item {
    margin: 0 !important;
  }

  .attach-images-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    background-color: @primary-color;
    color: @opposite-primary-color;
    border-radius: 50%;

    &:hover {
      color: @card-border-color !important;
    }
  }

  .attach-images-button-disabled {
    background-color: @card-border-color !important;
  }

  .form-item-message-textarea {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 15px;
    cursor: auto;

    &:not(.ant-input-status-error) {
      border-color: transparent;
    }

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }

    &:focus-visible {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .ant-input:disabled {
    background-color: @opposite-primary-color;
  }
}

.attach-image-popover {
  .popover-button {
    width: 100%;
    height: 24px;
    font-size: 10px;
    border-radius: 12px;
    text-transform: uppercase;
    box-shadow: 0 3px 20px 1px #00000008;
  }

  .attach-image-by-link-button {
    margin-bottom: 4px;
    background: @text-color;
    color: @opposite-primary-color;

    &:hover {
      background: @message-color;
      color: @opposite-primary-color !important;
      border-color: @message-color !important;
    }
  }
}

.image-previews {
  margin: 0 16px 0 16px;

  .image-preview {
    position: relative;
    margin-right: 8px;
    display: inline-block;

    .added-image-preview {
      border-radius: 4px;
    }

    .remove-preview-image-button {
      position: absolute;
      background-color: @text-color-secondary;
      width: 20px;
      height: 20px;
      top: -5px;
      right: -5px;
      border-radius: 10px;
      border: 1px solid @text-color;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.chat-message-images-preview {
  display: flex;
  flex-wrap: wrap;

  .image-preview {
    position: relative;
    margin-right: 8px;
    display: inline-block;

    .added-image-preview {
      border-radius: 4px;
    }
  }
}

.paragraph-images {
  border-radius: 10px;
  padding: 8px;
}

.paragraph {
  display: flex;
  flex-direction: column;
  //min-height: 48px;

  padding: 14px 17px;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  //white-space: pre-line;
  width: fit-content;
  word-wrap: break-word;
  color: @text-color;

  p {
    line-height: 28px;
  }
}

.function-call-paragraph {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

#paragraph-user {
  background: #E9E1FC;
  border-radius: 20px;
  min-height: 48px;
  //animation: fadeIn 0.1s ease-in-out;
  opacity: 1;
  line-height: 28px;
}

#paragraph-assistant {
  position: relative;
  opacity: 1;
  padding-inline: 0;
  padding: 0;

  p {
    font-size: 16px !important;
    margin: 0;
  }

  p, ul > li, ol > li {
    line-height: 28px;
  }
}

#user-paragraph-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#user-message-item {
  justify-content: right;
}

#chat-container {
  background: #ffffff73;
  box-shadow: 0 3px 20px 1px #00000008;
  border-radius: 10px;
  padding: 20px;
  height: 55vh;
  max-width: 100vw;
  overflow: auto;

  ul {
    padding: 0 0 0 21px;
  }
}

.chat-messages-list {
  display: flex;
  flex-direction: column;
  //gap: 34px;
  margin: 0;

  #user-paragraph-container {
    margin-top: 14px;
    margin-bottom: 34px;

    &:first-child {
      margin-top: 0;
    }
  }

  #assistant-paragraph-container {
    &:not(:last-child) {
      .like-message-buttons-wrapper {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s;
      }

      &:hover {
        .like-message-buttons-wrapper {
          visibility: visible;
          opacity: 1;
          transition: opacity 0.3s, visibility 0.3s;
        }
      }
    }
  }
}

@media screen and (max-width: 853px) {
  #chat-container {
    height: 100%;
  }
}

::-webkit-scrollbar-track {
  background: transparent;
}

.chat-header {
  height: 72px;
  background: #ffffffa3;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100vw;
  padding-block: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#chat-header-inner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 75vw;
}

.chat-buttons-container {
  display: flex;
  gap: 5px;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

